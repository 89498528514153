import { useState } from "react";
import { ButtonGroup } from 'ui';
import "./app.scss";

const translations = window.app.preloadState.translation;
const technicalInformation = (window as any).app.preloadState.technicalInformation;

function App() {

    const [key, setKey] = useState(0);

    return <div className="tab">
        <div className="tabHead" id="pdp-accordion-title">
            <ButtonGroup
                key={key}
                items={[
                    { isVisible: true, label: technicalInformation.functionalityHeading, value: 'functionality' },
                    { isVisible: technicalInformation.plainSectionItems.length > 0, label: technicalInformation.plainSectionHeading, value: 'plainTechnicalInfo' },
                    { isVisible: technicalInformation.accordionSectionItems.length > 0, label: technicalInformation.accordionSectionHeading, value: 'technicalInfo' },
                    { isVisible: technicalInformation.equipmentSectionItems.length > 0, label: technicalInformation.equipmentSectionHeading, value: 'equipment' },
                    { isVisible: true, label: technicalInformation.documentationHeading, value: 'documentation' }
                ]}
                theme={'Dark'}
                onChecked={(value: string) => {
                    document.getElementById(value)?.scrollIntoView({ behavior: 'smooth' });
                    setKey(Math.random());
                }}
                initialSelected={'functionality'} />
        </div>
        </div>
}

export default App;
